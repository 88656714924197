<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetylibrary">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <!--<toolbar-button role="export" @click="exportHandle()">{{ $t('export') }}</toolbar-button>-->
            <toolbar-button role="addRoot" v-if="$hasPermission('opm:safetylibrary:save')" @click="addOrUpdateHandle(null, '0', '无')"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:safetylibrary:delete')" @click="myDeleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="用于安全检查时选择安全检查项" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <vxe-table
          border="full"
          highlight-current-row
          show-overflow
          show-header-overflow
          header-align="center"
          ref="table"
          :row-config="{isHover: true, useKey: true, keyField: 'id'}"
          :column-config="{resizable: true}"
          :tree-config="treeConfig"
          :checkbox-config="{labelField: 'name'}"
          :data="dataList">
        <vxe-column type="checkbox" field="name" title="名称" min-width="200" tree-node align="left">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click.stop="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </vxe-column>
        <vxe-column field="code" title="编号" width="150" align="left"></vxe-column>
        <vxe-column field="sortNo" title="排序" align="center" width="120"></vxe-column>
        <vxe-column :title="$t('handle')" fixed="right" width="200" header-align="center" align="center">
          <template v-slot="{row}">
            <table-button role="addChild" @click="addChild(row)"></table-button>
            <!--<table-button icon="el-icon-circle-plus-outline" tooltip="添加同级" type="text" size="small" @click="addSibling(row)"></table-button>-->
            <table-button role="update" v-if="$hasPermission('opm:safetylibrary:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:safetylibrary:delete')" @click="myDeleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:safetylibrary:update'), row.name)"></table-button>
          </template>
        </vxe-column>
      </vxe-table>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './safetylibrary-add-or-update'
import ViewPage from './safetylibrary-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/safetyLibrary/list',
        exportURL: '/opm/safetyLibrary/export',
        deleteURL: '/opm/safetyLibrary',
      },
      dataForm: {
      },
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        line: true,
        reserve: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 插入子节点
    addChild(row) {
      this.addOrUpdateHandle(null, row.id, row.name)
    },
    // 插入兄弟节点
    addSibling(row) {
      let parentRow = this.$refs.table.getParentRow(row)
      if (!parentRow) {
        parentRow = {
          id: 0,
          name: '无'
        }
      }
      this.addOrUpdateHandle(null, parentRow.id, parentRow.name)
    },
    // 新增 / 修改
    addOrUpdateHandle (id, parentId, parentName) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.pid = parentId
        if (!parentName) {
          let parentRow = this.$refs.table.getParentRow(id)
          parentName = parentRow ? parentRow.name : '无'
        }
        this.$refs.addOrUpdate.dataForm.parentName = parentName
        this.$refs.addOrUpdate.init()
      })
    },
    // 查看
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        let parentRow = this.$refs.table.getParentRow(id)
        if (!parentRow) {
          this.$refs.view.dataForm.parentName = '无'
        } else {
          this.$refs.view.dataForm.parentName = parentRow.name
        }
        this.$refs.view.dataForm.id = id
        this.$refs.view.init()
      })
    },

    // 删除
    myDeleteHandle (id) {
      this.dataListSelections = this.$refs.table.getCheckboxRecords()
      this.deleteHandle(id)
    },
  }
}
</script>