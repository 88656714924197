<template>
  <el-dialog v-dialog-drag width="60%" :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="属性编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="属性名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="属性分类" prop="typeId">
            <el-select v-model="dataForm.typeId" :clearable="dataForm.id?false:true" placeholder="请选择属性分类">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        code: '',
        name: '',
        typeId: '',
        sortNo: ''
      },
      // 构件属性分类
      typeOptions: [
        {
          value: 1,
          label: '施工信息'
        },{
          value: 2,
          label: '试验信息'
        },{
          value: 3,
          label: '原材信息'
        }
      ],
    }
  },
  computed: {
    dataRule () {
      return {
        name: [
          {required: true,message: this.$t('validate.required'),trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          this.getNewSortNo()
        }
      })
    },
    // 获取排序序号
    getNewSortNo () {
      this.$http.get(`/mps/wbsProp/newSortNo`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.sortNo = res.data
      }).catch(() => {
        // do nothing
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/wbsProp/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/wbsProp/',this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    },1000,{'leading': true,'trailing': false})
  }
}
</script>