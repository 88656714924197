<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="节点类型" prop="typeId">
            <el-select v-model="dataForm.typeId" class="w-percent-100" disabled>
              <el-option :value=1 label="单位工程"></el-option>
              <el-option :value=2 label="分部工程"></el-option>
              <el-option :value=3 label="分项工程"></el-option>
              <el-option :value=4 label="检验批"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单位" prop="unitName">
            <el-input v-model="dataForm.unitName" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="alias1" label="别名1">
            <el-input v-model="dataForm.alias1" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="alias2" label="别名2">
            <el-input v-model="dataForm.alias2" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="alias3" label="别名3">
            <el-input v-model="dataForm.alias3" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="划分说明" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="autosize" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="特征字典" prop="features">
            <el-input type="textarea" v-model="dataForm.features" :autosize="autosize" readonly></el-input>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '',
        pids: '',
        name: '',
        code: '',
        typeId: '',
        unitName: ''
      },
      autosize: {
        minRows: 3,
        maxRows: 10
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/workstage/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
