<template>
  <el-dialog width="60%" :visible.sync="visible" title="查看" v-on="$listeners">
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="属性编号" prop="code">
            <el-input v-model="dataForm.code" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="属性名称" prop="name">
            <el-input v-model="dataForm.name" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="属性分类" prop="typeName">
            <el-input v-model="dataForm.typeName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input v-model="dataForm.sortNo" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        code: '',
        name: '',
        typeId: '',
        sortNo: '',
        typeName:''
      },
      // 构件属性分类
      typeOptions: [
        {
          value: 1,
          label: '施工信息'
        }, {
          value: 2,
          label: '试验信息'
        }, {
          value: 3,
          label: '原材信息'
        }
      ],
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },

    // 获取信息
    getInfo () {
      this.$http.get('/mps/wbsProp/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.dataForm.typeName=this.getTypeName(this.dataForm.typeId)

      }).catch(() => {
        // do nothing
      })
    },

    // 获取属性分类名称
    getTypeName(typeId){
      const val =this.typeOptions.find((element) => (element.value+'' === typeId + ''))
      if (val) {
        return val.label
      } else {
        return typeId||''
      }
    }
  }
}
</script>
