<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="code" label="编码">
            <el-input v-model="dataForm.code" placeholder="编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name" label="名称">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="节点类型" prop="typeId">
            <el-select v-model="dataForm.typeId" class="w-percent-100">
              <el-option :value=1 label="单位工程"></el-option>
              <el-option :value=2 label="分部工程"></el-option>
              <el-option :value=3 label="分项工程"></el-option>
              <el-option :value=4 label="检验批"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitName" label="单位">
            <el-input v-model="dataForm.unitName" placeholder="单位"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="alias1" label="别名1">
            <el-input v-model="dataForm.alias1" placeholder="别名1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="alias2" label="别名2">
            <el-input v-model="dataForm.alias2" placeholder="别名2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="alias3" label="别名3">
            <el-input v-model="dataForm.alias3" placeholder="别名3"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="上级节点" prop="pid">
            <workstage-parent-select v-model="dataForm.pid" :disabledId="dataForm.id"></workstage-parent-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="remark" label="划分说明">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="autosize"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="features" label="特征字典">
            <el-input type="textarea" v-model="dataForm.features" :autosize="autosize"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序序号">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import WorkstageParentSelect from './workstage-parent-select.vue'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '',
        pids: '',
        name: '',
        code: '',
        typeId: '',
        unitName: '',
        isLeaf: '',
        sortNo: '',
        features: '',
        pnames: '',
      },
      autosize: {
        minRows: 3,
        maxRows: 10
      },
      oldPid: ''
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        typeId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
    }
  },
  components: {
    WorkstageParentSelect
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 获取排序序号
          this.getNewSortNo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/workstage/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        this.oldPid = this.dataForm.pid
      }).catch(() => {
      })
    },
    // 获取排序序号
    getNewSortNo() {
      this.$http.get(`/mps/workstage/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.sortNo = res.data
      }).catch(() => {
        // do nothing
      })
    },

    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/workstage/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (this.dataForm.id) {
            this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
          } else {
            this.$emit('addNode', this.dataForm.pid)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>